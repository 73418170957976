import type { I18nLocale } from "../en";
import error from "./error";
import saleor from "./saleor";
import support from "./support";

export default {
  error,
  saleor,
  support,
  title: "Genotic",
  common: {
    cancel: "Abbrechen",
    close: "Schließen",
    text: "Text",
    edit: "Bearbeiten",
    save: "Speichern",
    add: "Hinzufügen",
    day: "Tag | Tage",
    show: "Anzeigen",
    hide: "Verbergen",
    download: "Herunterladen | Herunterladungen",
    search: "Suchen",
    scrollToTop: "Nach oben scrollen",
    delete: "Löschen",
    or: "oder",
    submit: "Absenden",
    submitting: "Absenden",
    open: "Öffnen",
  },
  unit: {
    pc: "Stk",
  },
  footer: {
    termsAndConditions: "Nutzungsbedingungen",
    privacyPolicy: "Datenschutzrichtlinie",
    new: "Neu",
    hiring: "Karriere",
  },
  header: {
    all: "Alle",
    myAccount: "Mein Konto",
    cart: "Warenkorb",
    signOut: "Abmelden",
    languageDisplay: "Deutsch (EUR)",
    countryWithCurrency: "Deutschland (EUR)",
    menu: "Menü",
    search: "Suche",
  },
  profile: {
    title: "Profil",
    info: "Info",
    addresses: "Adressen",
    giftCards: "Geschenkkarten",
    orders: {
      title: "Bestellungen",
      empty: "Sie haben noch keine Bestellungen erstellt",
    },
    editProfile: "Profil bearbeiten",
  },
  order: {
    cart: {
      empty: "Ihr Warenkorb ist leer",
      title: "Warenkorb",
    },
    checkout: {
      title: "Bestellung",
      addAnythingToCartToContinue: "Fügen Sie etwas zum Warenkorb hinzu, um fortzufahren",
      goBackToStore: "Zurück zum Shop",
      delivery: "Lieferung",
      completeCheckout: "Bestellung abschließen",
      haveAPromoCode: "Haben Sie einen Gutscheincode?",
      promoCode: "Gutscheincode",
      specifyHowYouWantToPlaceYourOrder: "Geben Sie an, wie Sie Ihre Bestellung platzieren möchten",
      continueAsGuest: "Als Gast fortfahren",
      continueAsGuestDescription:
        "In jedem Moment des Bestellprozesses können Sie ein kostenloses Mitgliederprofil erstellen",
      iHaveAnAccount: "Ich habe bereits ein Konto",
      createAccount: "Konto erstellen",
      continueAsMember: "Als Mitglied fortfahren",
    },
    steps: {
      address: "Adresse",
      shipping: "Versand",
      payment: "Zahlung",
      continue: "Fortfahren",
      back: "Zurück",
    },
    paymentMethods: {
      title: "Zahlungsmethoden",
      bankTransfer: "Banküberweisung",
    },
    number: "Bestellnummer",
    status: "Status",
    paid: "Bezahlt",
    unpaid: "Unbezahlt",
    paymentStatus: "Zahlungsstatus",
    complete: "Bestellung abschließen",
    quantity: "Menge",
    info: "Bestellinformationen",
    arrives: "Lieferung",
    addToCart: "In den Warenkorb",
    addedToCart: "Produkt {product} wurde Ihrem Warenkorb hinzugefügt",
    goToCart: "Zum Warenkorb",
    goToCheckout: "Zur Kasse",
    shippingTo: "Versand nach",
    total: "Total",
    subtotal: "Zwischensumme",
    shipping: {
      title: "Versand",
      country: "Deutschland",
      noShippingMethodsAvailable: "Keine Versandmethoden verfügbar",
    },
  },
  auth: {
    login: "Anmelden",
    register: "Registrieren",
    logout: "Abmelden",
    email: "E-Mail",
    password: "Passwort",
    password2: "Passwort bestätigen",
    firstName: "Vorname",
    lastName: "Nachname",
    dontHaveAnAccount: "Haben Sie kein Konto?",
    alreadyHaveAnAccount: "Haben Sie bereits ein Konto?",
    forgotPassword: "Passwort vergessen?",
    createNewAccount: "Neues Konto erstellen",
  },
  address: {
    title: "Adresse",
    shippingAddress: "Lieferadresse",
    billingAddress: "Rechnungsadresse",
    defaultAddress: "Standardadresse",
    thisAddressIsUsedForBothShippingAndBilling:
      "Diese Adresse wird für Lieferung und Rechnung verwendet",
    defaultForShippingAndBilling: "Standard für Lieferung und Rechnung",
    defaultShippingAddress: "Standardlieferadresse",
    defaultBillingAddress: "Standardrechnungsadresse",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Straße und Hausnummer",
    streetAddress2: "Adresszusatz",
    postalCode: "Postleitzahl",
    city: "Stadt",
    cityArea: "Bundesland",
    country: "Deutschland",
    email: "E-Mail",
    countryArea: "Bundesland",
    vat: "VAT",
    selectACountry: "Wählen Sie ein Land aus",
    companyName: "Firmenname",
    phoneNumber: "Telefonnummer",
    billingMatchesShippingAddress: "Rechnungsadresse und Lieferadresse stimmen überein",
    editAddress: "Adresse bearbeiten",
    deleteAddress: "Adresse löschen",
    addAddress: "Adresse hinzufügen",
    noAddress: "Keine Adresse",
    noDefaultAddress: "Keine Standardadresse",
    empty: "Sie haben noch keine Adressen hinzugefügt",
    organizationInfo: "Organisationsinformationen",
  },
  product: {
    title: "Produkt | Produkte",
    addToCart: "In den Warenkorb",
    addedToCart: {
      toastTitle: "Produkt {product} wurde Ihrem Warenkorb hinzugefügt",
      toastDescription: "Sie können es jetzt dort ansehen...",
    },
    outOfStock: "Nicht vorrätig",
    synonyms: "Synonyme",
    noProductsFound: "Keine Produkte gefunden",
    description: {
      recommendedDilution: "Empfohlene Verdünnung",
      applications: "Anwendung | Anwendungen",
      dilution: "Verdünnung",
      testedApplications: "Getestete Anwendungen",
      targetSpecies: "Zielarten",
      targetAliases: "Zielalias",
      species: "Arten",
      uniProtIdAndGenId: "UniProt ID und Gen ID",
      images: "Bild | Bilder",
      publications: "Veröffentlichung | Veröffentlichungen",
      authors: "Autor | Autoren",
      performanceData: "Leistungsdaten",
      support: "Unterstützung",
      productSpecificProtocols: "Produktspezifische Protokolle",
      frequentlyAskedQuestions: "Häufig gestellte Fragen",
      contact: "Kontakt",
      productDetails: "Produktdetails",
      targetInfo: "Zielinformationen",
      relatedProducts: "Ähnliches Produkt | Ähnliche Produkte",
      files: "Dateien",
    },
  },
  ui: {
    availability: {
      available: "Verfügbar",
      unavailable: "Nicht verfügbar",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Kategorie | Kategorien",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Kategorien",
    noCategoriesFound: "Keine Kategorien gefunden",
  },
  warning: {
    thisActionCannotBeUndone: "Diese Aktion kann nicht rückgängig gemacht werden",
  },
  search: {
    closeSearchResults: "Suchergebnisse schließen",
    searchResultsInIndividualCategories: "Suchergebnisse in einzelnen Kategorien",
    resultsInIndividualCategories: "Suchergebnisse in einzelnen Kategorien",
  },
} satisfies I18nLocale;
