import type { ErrorBase } from "../types";

const errorType = {
  NO_CHECKOUT_ID: "Brak koszyka",
  NO_CHECKOUT: "Brak koszyka",
  UNAUTHORIZED: "Brak dostępu",
} satisfies ErrorBase;

const error = {
  type: errorType,
  somethingWentWrong: "Coś poszło nie tak",
  backToHome: "Powrót do strony głównej",
  "404": "Nie można znaleźć tej strony",
};

export type EnErrorBase = typeof error;

export default error;
