import type { I18nLocale } from "../en";
import error from "./error";
import saleor from "./saleor";
import support from "./support";

export default {
  support,
  saleor,
  error,
  title: "Genotic",
  common: {
    cancel: "Anuluj",
    text: "Tekst",
    edit: "Edytuj",
    save: "Zapisz",
    add: "Dodaj",
    day: "Dzień | Dni",
    show: "Pokaż",
    hide: "Ukryj",
    close: "Zamknij",
    download: "Pobierz | Pobrane",
    search: "Szukaj",
    scrollToTop: "Przewiń do góry",
    delete: "Usuń",
    or: "lub",
    submit: "Zatwierdź",
    submitting: "Zatwierdzanie",
    open: "Otwórz",
  },
  unit: {
    pc: "szt",
  },
  footer: {
    termsAndConditions: "Regulamin",
    privacyPolicy: "Polityka prywatności",
    new: "Nowe",
    hiring: "Zatrudniamy!",
  },
  header: {
    all: "Wszystko",
    myAccount: "Konto",
    cart: "Koszyk",
    signOut: "Wyloguj",
    languageDisplay: "Polski (PLN)",
    countryWithCurrency: "Polska (PLN)",
    menu: "Menu",
    search: "Szukaj",
  },
  search: {
    closeSearchResults: "Zamknij wyniki wyszukiwania",
    searchResultsInIndividualCategories: "Wyniki wyszukiwania w poszczególnych kategoriach",
    resultsInIndividualCategories: "Wyniki w poszczególnych kategoriach",
  },
  profile: {
    title: "Profil",
    info: "Informacje",
    addresses: "Adresy",
    giftCards: "Karty podarunkowe",
    orders: {
      title: "Zamówienia",
      empty: "Nie utworzono żadnych zamówień",
    },
    editProfile: "Edytuj profil",
  },
  address: {
    title: "Adres",
    shippingAddress: "Adres dostawy | Adresy dostawy",
    billingAddress: "Adres rozliczeniowy | Adresy rozliczeniowe",
    defaultAddress: "Adres domyślny",
    thisAddressIsUsedForBothShippingAndBilling: "Ten adres jest używany do wysyłki i rozliczenia",
    defaultForShippingAndBilling: "Domyślny dla wysyłki i rozliczenia",
    defaultShippingAddress: "Domyślny adres dostawy",
    defaultBillingAddress: "Domyślny adres rozliczeniowy",
    firstName: "@:auth.firstName",
    lastName: "@:auth.lastName",
    streetAddress1: "Linia adresowa 1",
    streetAddress2: "Linia adresowa 2",
    postalCode: "Kod pocztowy",
    city: "Miasto",
    cityArea: "Obszar miasta",
    email: "@:auth.email",
    country: "Kraj",
    countryArea: "Stan / Województwo",
    vat: "VAT",
    selectACountry: "Wybierz kraj",
    companyName: "Nazwa firmy",
    phoneNumber: "Numer telefonu",
    billingMatchesShippingAddress: "Adres rozliczeniowy taki sam jak adres dostawy",
    editAddress: "Edytuj adres",
    deleteAddress: "Usuń adres",
    addAddress: "Dodaj adres",
    noAddress: "Brak adresu",
    noDefaultAddress: "Brak domyślnego adresu",
    empty: "Nie dodano żadnych adresów",
    organizationInfo: "Informace o organizacji",
  },
  order: {
    cart: {
      empty: "Twój koszyk jest pusty",
      title: "Koszyk",
    },
    checkout: {
      title: "Zamówienie",
      addAnythingToCartToContinue: "Dodaj coś do koszyka, aby kontynuować",
      goBackToStore: "Wróć do sklepu",
      delivery: "Dostawa",
      completeCheckout: "Zakończ zamówienie",
      haveAPromoCode: "Masz kod promocyjny?",
      promoCode: "Kod promocyjny",
      specifyHowYouWantToPlaceYourOrder: "Określ, jak chcesz umieścić swoje zamówienie",
      continueAsGuest: "Kontynuuj jako gość",
      continueAsGuestDescription:
        "W każdej chwili procesu zamawiania możesz utworzyć bezpłatny profil członka",
      continueAsMember: "Kontynuuj jako członek",
      createAccount: "Utwórz konto",
      iHaveAnAccount: "Mam już konto",
    },
    steps: {
      address: "Adres",
      shipping: "Dostawa",
      payment: "Płatność",
      continue: "Kontynuuj",
      back: "Wstecz",
    },
    paymentMethods: {
      title: "Metody płatności",
      bankTransfer: "Przelew bankowy",
    },
    number: "Numer zamówienia",
    status: "Status",
    paid: "Opłacone",
    unpaid: "Nieopłacone",
    paymentStatus: "Status płatności",
    complete: "Złóż zamówienie",
    quantity: "Ilość",
    info: "Informacje o zamówieniu",
    arrives: "dostawa",
    addToCart: "Dodaj do koszyka",
    addedToCart: "Produkt {product} został dodany do twojego koszyka",
    goToCart: "Przejdź do koszyka",
    goToCheckout: "Przejdź do kasy",
    shippingTo: "Wysyłka do",
    total: "Suma",
    subtotal: "Suma częściowa",
    shipping: {
      title: "wysyłka",
      country: "Polska",
      noShippingMethodsAvailable: "Brak dostępnych metod dostawy",
    },
  },
  auth: {
    login: "Zaloguj",
    register: "Zarejestruj się",
    logout: "Wyloguj się",
    email: "E-mail",
    password: "Hasło",
    password2: "Powtórz hasło",
    firstName: "Imię",
    lastName: "Nazwisko",
    alreadyHaveAnAccount: "Masz już konto?",
    dontHaveAnAccount: "Nie masz konta?",
    forgotPassword: "Zapomniałeś hasła?",
    createNewAccount: "Utwórz nowe konto",
  },
  product: {
    title: "Produkt | Produkty",
    addToCart: "Dodaj do koszyka",
    addedToCart: {
      toastTitle: "Produkt {product} został dodany do twojego koszyka",
      toastDescription: "Możesz teraz go tam zobaczyć...",
    },
    outOfStock: "Brak w magazynie",
    synonyms: "Synonimy",
    noProductsFound: "Nie znaleziono produktów",
    description: {
      recommendedDilution: "Zalecane rozcieńczenie",
      applications: "Zastosowanie | Zastosowania",
      dilution: "Rozcieńczenie",
      testedApplications: "Przetestowane zastosowania",
      targetSpecies: "Gatunki docelowe",
      targetAliases: "Aliasy docelowe",
      species: "Gatunki",
      uniProtIdAndGenId: "UniProt ID i Gen ID",
      images: "Obraz | Obrazy",
      publications: "Publikacja | Publikacje",
      authors: "Autor | Autorzy",
      performanceData: "Dane wydajności",
      support: "Wsparcie",
      productSpecificProtocols: "Specyficzne protokoły produktu",
      frequentlyAskedQuestions: "Często zadawane pytania",
      contact: "Kontakt",
      productDetails: "Szczegóły produktu",
      targetInfo: "Informacje o celu",
      relatedProducts: "Powiązany produkt | Powiązane produkty",
      files: "Pliki",
    },
  },
  ui: {
    availability: {
      available: "Dostępny",
      unavailable: "Niedostępny",
      timespan: "{from} - {to} @.lower:common.day",
    },
    breadcrumbs: {
      categories: "Kategoria | Kategorie",
      products: "@:product.title",
    },
  },
  categories: {
    title: "Kategorie",
    noCategoriesFound: "Nie znaleziono żadnej kategorii",
  },
  warning: {
    thisActionCannotBeUndone: "Ta akcja nie może być cofnięta",
  },
} satisfies I18nLocale;
