import type { ErrorBase } from "../types";

const errorType = {
  NO_CHECKOUT_ID: "Pas d'identifiant de paiement",
  NO_CHECKOUT: "Pas de paiement",
  UNAUTHORIZED: "Non autorisé",
} satisfies ErrorBase;

const error = {
  type: errorType,
  somethingWentWrong: "Quelque chose s'est mal passé",
  backToHome: "Retour à la page d'accueil",
  "404": "Cette page n'a pas pu être trouvée",
};

export type EnErrorBase = typeof error;

export default error;
