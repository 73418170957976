import type { Saleor, SaleorErrors } from "../en/saleor";

const errors = {
  common: {
    UNIQUE: "Único",
    INVALID: "Inválido",
    NOT_FOUND: "No encontrado",
  },
  CheckoutError: {
    VOUCHER_NOT_APPLICABLE: "Vale no aplicable",
    REQUIRED: "Requerido",
    NO_LINES: "Sin líneas",
    INVALID: "Inválido",
    INVALID_SHIPPING_METHOD: "Método de envío inválido",
    QUANTITY_GREATER_THAN_LIMIT: "Cantidad mayor que el límite",
    EMAIL_NOT_SET: "Email no establecido",
    NOT_FOUND: "No encontrado",
    PRODUCT_NOT_PUBLISHED: "Producto no publicado",
    PRODUCT_UNAVAILABLE_FOR_PURCHASE: "Producto no disponible para compra",
    INSUFFICIENT_STOCK: "Stock insuficiente",
    ZERO_QUANTITY: "Cantidad a cero",
    UNAVAILABLE_VARIANT_IN_CHANNEL: "Variante no disponible en el canal",
  },
  AccountError: {
    INVALID_CREDENTIALS: "Credenciales inválidas",
    INVALID_PASSWORD: "Contraseña inválida",
    NOT_FOUND: "No encontrado",
    PASSWORD_TOO_SHORT: "Contraseña demasiado corta",
    PASSWORD_TOO_COMMON: "Contraseña demasiado común",
    PASSWORD_TOO_SIMILAR: "Contraseña demasiado similar",
    REQUIRED: "Requerido",
    UNIQUE: "Único",
    ACCOUNT_NOT_CONFIRMED: "Cuenta no confirmada",
  },
  TransactionInitializeError: {
    CHECKOUT_COMPLETION_IN_PROGRESS: "Finalización de compra en curso",
  },
  TransactionProcessError: {
    NOT_FOUND: "Transacción no encontrada",
    TRANSACTION_ALREADY_PROCESSED: "Transacción ya procesada",
    INVALID: "Inválido",
    CHECKOUT_COMPLETION_IN_PROGRESS: "Finalización de compra en curso",
  },
} satisfies SaleorErrors;

export default {
  errors,
  order: {
    Status: {
      CANCELED: "Cancelado",
      DRAFT: "Borrador",
      EXPIRED: "Expirado",
      FULFILLED: "Cumplido",
      PARTIALLY_FULFILLED: "Cumplido parcialmente",
      PARTIALLY_RETURNED: "Devuelto parcialmente",
      RETURNED: "Devuelto",
      UNCONFIRMED: "No confirmado",
      UNFULFILLED: "No cumplido",
    },
    ChargeStatus: {
      FULL: "Completo",
      NONE: "Ninguno",
      OVERCHARGED: "Sobrecargado",
      PARTIAL: "Parcial",
    },
  },
  payment: {
    ChargeStatus: {
      CANCELLED: "Cancelado",
      FULLY_CHARGED: "Cargado completamente",
      FULLY_REFUNDED: "Reembolsado completamente",
      NOT_CHARGED: "No cargado",
      PARTIALLY_CHARGED: "Cargado parcialmente",
      PARTIALLY_REFUNDED: "Reembolsado parcialmente",
      PENDING: "Pendiente",
      REFUSED: "Rechazado",
    },
  },
} satisfies Saleor;
