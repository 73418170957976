import type { EnErrorBase } from "../en/error";

export default {
  type: {
    NO_CHECKOUT_ID: "Keine Checkout ID",
    NO_CHECKOUT: "Keine Checkout",
    UNAUTHORIZED: "Nicht autorisiert",
  },
  somethingWentWrong: "Quelque chose s'est mal passé",
  backToHome: "Zurück zur Startseite",
  "404": "Diese Seite konnte nicht gefunden werden",
} satisfies EnErrorBase;
